<template>
  <div>
    <h2>自己PR</h2>
    <b-card
      title=""
    >
      <div
        v-for="(item,index) in menulist"
        :key="index"
        :class="index ? 'mt-2':''"
      >
        <b-row>
          <b-col lg="4">
            <h5
              class="text-capitalize mb-75"
            >
              {{ item.label }}
            </h5>
          </b-col>
          <b-col>
            <b-card-text>
              <div
                v-if="profileDic[item.key]"
              >
                {{ profileDic[item.key] }}
              </div>
              <div
                v-else
              >
                登録なし
              </div>
            </b-card-text>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'
import menulist from '@/components/conf/JPCAREER様_就労PF_求職者_自己PR.json'
import getSingleData from '@/firestore/data/getByDocID'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
      menulist,
      profileDic: {},
    }
  },
  async mounted() {
    const data = {
      collection: 'selfPR',
      uid: 'zGySjFgg5pcpV09impD2oCsQvwm2',
    }
    const response = await getSingleData(data)
    if (response.status === 'success') {
      this.profileDic = response.data
    } else {
      window.console.log('error', response.msg)
    }
  },
  methods: {
  },
}
</script>
