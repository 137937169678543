<template>
  <div>
    <h2>資格一覧</h2>
    <b-card
      title=""
    >
      <div
        v-for="(item,index) in dataList"
        :key="index"
        :class="index ? 'mt-2':''"
      >
        <b-row>
          <b-col md="6">
            <h5
              class="text-capitalize mb-75"
            >
              [ {{ index + 1 }} ]
              <span v-if="item.skillQualification !== void 0">{{ item.skillQualification }}</span>
            </h5>
          </b-col>
          <!-- submit and reset -->
          <b-col md="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="flat-primary"
              @click="detail(item.docID)"
            >
              詳細
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import getDataList from '@/firestore/data/getList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
      dataList: [],
    }
  },
  async mounted() {
    const data = {
      collection: 'qualification',
      uid: 'zGySjFgg5pcpV09impD2oCsQvwm2',
    }
    const response = await getDataList(data)
    if (response.status === 'success') {
      this.dataList = response.data
    } else {
      window.console.log('error', response.msg)
    }
  },
  methods: {
    detail(id) {
      this.$router.push({ path: '/job-qualification', query: { id } })
    },
    add() {
      this.$router.push({ path: '/job-qualification-edit', query: { met: 'create' } })
    },
  },
}
</script>
