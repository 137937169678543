<template>
  <div>
    <h2>履歴書</h2>
    <b-tabs>
      <b-tab title="プロフィール">
        <Profile />
      </b-tab>
      <b-tab title="学歴">
        <BackGround />
      </b-tab>
      <b-tab title="職歴">
        <History />
      </b-tab>
      <b-tab title="資格">
        <Qualification />
      </b-tab>
      <b-tab title="語学">
        <LanguageSkill />
      </b-tab>
      <b-tab title="スキル">
        <SkillProduction />
      </b-tab>
      <b-tab title="PR">
        <PR />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import Profile from '@/components/company/seachJobSeeker/Profile.vue'
import BackGround from '@/components/company/seachJobSeeker/BackGround.vue'
import History from '@/components/company/seachJobSeeker/History.vue'
import Qualification from '@/components/company/seachJobSeeker/Qualification.vue'
import LanguageSkill from '@/components/company/seachJobSeeker/LanguageSkill.vue'
import SkillProduction from '@/components/company/seachJobSeeker/SkillProduction.vue'
import PR from '@/components/company/seachJobSeeker/PR.vue'

export default {
  components: {
    BTabs,
    BTab,
    Profile,
    BackGround,
    History,
    Qualification,
    LanguageSkill,
    SkillProduction,
    PR,
  },
  directives: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>
